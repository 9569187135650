@import "~dolfo2/styles/dolfo";
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700&display=swap");

$pmTheme: (
    "logoGradient1": #fe3b4c,
    "logoGradient2": #fe6b60,
    "ruby": #FE3B4C,
    "fireRed": #FE6B60,
    "lapras": #45B2FF,
    "black": #25221F
);

html{
    background-color: $lightThemeLightGrey
}

body{
    color: $black;
    background-color: inherit;
    font-family: "Nunito", sans-serif
}

.dolfo-table-container{
    box-shadow: var(--bs-box-shadow-sm);
    border-radius: $smallRound;
    
    table{
        border-collapse: initial
    }
}

table tr th{
    font-weight: bold !important
}

.top-dialog{
    align-self: flex-start
}

.login-container{
    @include full-flex;
    flex-direction: column;
    height: 100vh;
	background: linear-gradient(to right, map-get($pmTheme, logoGradient2), map-get($pmTheme, logoGradient1));

    .login-box{
        width: 400px
    }

    > img{
        max-width: 250px
    }
}

.pm-card{
    box-shadow: var(--bs-box-shadow-sm);
    padding: 1rem;
    border-radius: 0.2rem;
    background-color: $white;

    &.bordered{
        border: 1px solid lighten($lightThemeGrey, 30%)
    }
}

.header{
    @include flex;
    @include align-center;
    position: sticky;
    top: 0;
    padding: 0 1rem;
    height: 55px;
    background-color: $white;
    z-index: 2;

    > a{
        height: 80%;

        .pm-logo{
            @include pointer;
            @include inline-block;
            height: 100%;
            margin-left: 0.5rem;
            filter: brightness(0%)
        }
    }
}

.loading-dialog.dolfo-dialog .dolfo-dialog-header{
    display: none
}

.content{
    $pad: 0.5rem;
    width: 100%;
    padding: 1rem;
    overflow: auto
}

.loading-bar{
    @include fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 3;
    
    &:after{
        @include base-pseudo;
        height: 100%;
        width: 50%;
        background-color: $lightThemeRed;
        border-radius: $fullRound;
        animation: expand 3s ease infinite
    }
}

.pairing-table{
    tbody tr{
        td{
            &.pairing-cell{
                padding: 0.7rem !important;
                white-space: pre-wrap
            }
        }

        &:hover td:nth-child(even){
            background-color: $white !important
        }
    }
}

.bg-red{
    background-color: lighten($lightThemeRed, 52%) !important;
    color: $lightThemeRed
}

.bg-green{
    background-color: lighten($lightThemeGreen, 52%) !important;
    color: darken($lightThemeGreen, 10%)
}

.bg-blue2{
    background-color: lighten($lightThemeBlue2, 50%) !important;
    color: darken($lightThemeBlue2, 10%)
}

.bg-yellow{
    background-color: lighten($lightThemeYellow, 45%) !important;
    color: darken($lightThemeYellow, 10%)
}

.g-button{
    @include full-flex;
    margin-top: 1rem
}

.white-space-prewrap{
    white-space: pre-wrap !important
}

.dolfo-control.radio{
    flex-wrap: wrap;

    label{
        flex: 0 0 100%;
        margin-bottom: -0.5rem
    }
}

.dolfo-dropdown-option.login-info{
    pointer-events: none
}

.editor-container{
    position: relative;

    > input[type="text"]{
        position: absolute;
        top: 2rem;
        opacity: 0;
        pointer-events: none
    }

    .rsw-editor{
        border-radius: $smallRound;
        border-color: #aaa;
    
        &.disabled{
            opacity: $disabledOpacity;
            pointer-events: none
        }
    
        .rsw-toolbar{
            .rsw-separator{
                margin: 0
            }
    
            > select{
                padding: 0.3rem 0.5rem;
                cursor: pointer;
                font: inherit;
                border: 0;
    
                > option{
                    @include pointer
                }
            }
        }
    }
}

@keyframes expand{
    from{
        margin-left: -50%
    }
    to{
        margin-left: 100%
    }
}

.category-card{
    @include pointer;
    @include full-flex;
    text-align: center;
    background-color: $white;
    border: 1px solid transparent;

    img{
        max-width: 100%
    }

    &:hover{
        background-color: lighten($lightThemeBlue2, 50%);
        border-color: $lightThemeBlue2
    }
}

@media screen and (max-width: 600px){
    .hide-mobile{
        display: none
    }
}